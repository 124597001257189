import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { node } from "prop-types";
import archImage from "../images/wedding-arch.svg";
import rsvpImage from "../images/rsvp.svg";
import partyImage from "../images/party.svg";
import celebrationImage from "../images/party.png";

const IndexPage = (props) => (
  <Layout location={props.location}>

							 <article className="post featured"> 
							<img src={celebrationImage} className="image icon"/>
								<header class="major">
									<h2>Kevin and Alin are getting married</h2>
									<span class="date">August 10th, 2019</span>
								</header>
								<div classname="image main">
									<Img fluid={props.data.kevinAlinKiss.childImageSharp.fluid} />
								</div>
							</article>
							<section className="posts">

								<article>
									<img src={archImage} className="image icon"/>
									<header>
										<h2>Ceremony</h2>
									</header>
									<div className="image fit">
										<Img fluid={props.data.leGermain.childImageSharp.fluid}/>
									</div>
									<p>3:15pm<br></br> <br></br>Le Germain Hotel<br></br>899 Centre St SW<br></br>Calgary, AB T2G 1B8</p>
									<ul className="actions special">
										<li>
											<a href="https://goo.gl/maps/Qd5BinV34JWrusWu7" className="button">Directions</a>
										</li>
									</ul>
								</article>

								<article>
								<img src={partyImage} className="image icon"/>
									<header>
										<h2>Reception</h2>
									</header>
									<div className="image fit">
										<Img fluid={props.data.alForno.childImageSharp.fluid} />
									</div>
									<p>5:00pm<br></br> <br></br>Alforno Cafe and Bakery<br></br>222 7 Street SW<br></br>Calgary, AB T2P 0E4</p>
									<ul className="actions special">
										<li>
											<a href="https://goo.gl/maps/cGRZzMg3sLM5tS6T6" className="button">Directions</a>
										</li>
									</ul>
								</article>
							</section>
							<section >
								<article className="post featured">
								<img src={rsvpImage} className="image icon" />
									<header className="major">
										<h2>RSVP</h2>
										<p>We hope that you're able to join us!</p>
									</header>
									<ul className="actions special">
									<li>
										<Link to="/rsvp" className="button large">RSVP NOW</Link>
									</li>
									</ul>
								</article>

							</section>*
  </Layout>
)

export const query = graphql`
query {
	kevinAlinKiss: file(relativePath: { eq: "kevin-alin-kiss.jpg" }) {
		childImageSharp {
			fluid(maxWidth: 1195) {
				...GatsbyImageSharpFluid_withWebp
			}
	 } 
	},
	leGermain: file(relativePath: { eq: "le-germain.jpg" }) {
		childImageSharp {
			fluid(maxWidth: 995) {
				...GatsbyImageSharpFluid_withWebp
			}
	 } 
	},
	alForno: file(relativePath: { eq: "alforno.jpg" }) {
		childImageSharp {
			fluid(maxWidth: 1195) {
				...GatsbyImageSharpFluid_withWebp
			}
	 } 
	},
}
`

export default IndexPage
